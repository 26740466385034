/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-popover.search-popover {
    --background: #1b171b;
    --width: 100vw;
    --offset-y: 5px; 
}

ion-popover.search-popover::part(content) {
    border-radius: 0px;
    left: calc(0px + var(--offset-x, 0px)) !important;
}

.header-ios.app-header ion-toolbar:last-of-type  {
    --border-width: 0px 0 3px;
}

.color-red-asterisk{
    color: var(--ion-color-danger);
}

.text-size h2 {
    font-size: 16px !important;
}

ion-header{
    ion-title.ios {
        font-size: 25px;
    }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]){
    display: contents;
  }

